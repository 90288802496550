<template>
  <div>
    <a-drawer :title="title" :width="720" :visible="show" :body-style="{ paddingBottom: '80px' }" @close="addClose">
      <div class="drawer-content">
        <span style="font-weight: 600">投票信息</span>
        <a-divider></a-divider>
		<a-form-model ref="ruleForm" :model="form" :rules="rules">
			<a-row>
				<a-col :span="12">
					<a-form-model-item label="投票标题" prop="title">
						<a-input v-model="form.title" placeholder="请输入标题" style="width: 80%"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col :span="12">
					<a-form-model-item label="投票时间" :rules="{required: true,message:'请选择日期',trigger:'change'}">
						<a-range-picker v-model="selTime" @change="changeTime" value-format="YYYY-MM-DD HH:mm:ss" style="width: 80%"></a-range-picker>
					</a-form-model-item>
				</a-col>
				<a-col :span="12">
					<a-form-model-item label="公开人群" prop="type">
						<a-select v-model="form.type" placeholder="请选择对象" style="width: 80%">
							<a-select-option :value="1">全部</a-select-option>
							<a-select-option :value="2">业主</a-select-option>
							<a-select-option :value="3">业主亲属</a-select-option>
							<a-select-option :value="4">租户</a-select-option>
							<a-select-option :value="5">租户亲属</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="12">
					<a-form-model-item label="是否发布" prop="isRelease">
						<a-select v-model="form.isRelease" placeholder="请选择" style="width: 80%">
							<a-select-option :value="1">发布</a-select-option>
							<a-select-option :value="0">不发布</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="24">
					<a-form-model-item label="投票内容" prop="content">
						<a-textarea v-model="form.content" placeholder="请输入内容" style="width: 90%"></a-textarea>
					</a-form-model-item>
				</a-col>
				<a-col :span="24">
					<a-form-model-item label="图片上传">
						<commonUpload :fileList="fileList" @handleChange="handleChange"></commonUpload>
					</a-form-model-item>
				</a-col>
				<a-col :span="24">
					<a-form-model-item label="投票选项">
						<a-button @click="addVoteItem">添加投票选项</a-button>
						<div class="vote-item" v-for="(item,index) in form.voteUpdateCandidateDTOList" :key="index">
							<div class="vote-item-title">
								<span style="font-weight: 600">选项{{index+1}}</span>
								<a @click="deleteVoteItem(item,index)" style="margin-left: 20px">删除</a>
							</div>
							<a-form-model layout="inline">
								<a-form-model-item label="选项名称">
									<a-input v-model="item.name"></a-input>
								</a-form-model-item>
								<a-form-model-item label="手机号">
									<a-input v-model="item.tel"></a-input>
								</a-form-model-item>
								<a-form-model-item>
									<a-upload
										:action="`${$upload}`"
										:multiple="false"
										accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
										:headers="uploadHeaders"
										@change="voteImgChange"
										:file-list="item.defaultFileList"
									>
										<a-button @click="uploadIndex(index)"> <a-icon type="upload" />点击上传</a-button>
									</a-upload>
									<!-- <a-upload
										name="file"
										:multiple="true"
										:action="`${$upload}`"
										:file-list="item.defaultFileList"
										accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
										:headers="uploadHeaders"
										@change="voteImgChange"
										:beforeUpload='beforeUpload'
									>
										<a-button @click="uploadIndex(index)"> <a-icon type="upload" />点击上传</a-button>
									</a-upload> -->
								</a-form-model-item>
							</a-form-model>
						</div>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
	  </div>
		<div class="drawer-footer">
			<a-button :style="{ marginRight: '8px' }" @click="addClose">
			关闭
			</a-button>
			<a-button type="primary" @click="submit"> 提交 </a-button>
		</div>
    </a-drawer>
  </div>
</template>

<script>
import store from "@/store";
import { findVote, updateVote} from "@/api/operation/vote"
import { form,rules } from "./config.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "修改投票",
			form: {
				title: '',
				content: '',
				beginDate: '',
				endDate: '',
				type: 1,
				isRelease: 0,
				voteUpdateCandidateDTOList: [],
				imgUrls: [],
			},
			rules: rules,
			selTime: [],
			fileList: [],
			rule: [],
			activeId: undefined,
			deleteId: [], //删除的选项的Id

			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
			activeIndex: undefined,
		};
	},
	mounted() {
		
	},
	methods: {
		addClose() {
			this.fileList = [];
			this.form = {
				title: '',
				content: '',
				beginDate: '',
				endDate: '',
				type: 1,
				isRelease: 0,
				voteInsertCandidateDTOList: [],
				imgUrls: [],
			};
			this.deleteId = [];
			this.selTime = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					let obj = Object.assign(this.form, {id: this.activeId,deleteVoteCandidateIds: this.deleteId})
					updateVote(obj).then(res => {
						if(res.code === 200){
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					})	
				}
			});
		},
		//图片上传
		handleChange(data) {
			this.fileList = data;
			this.form.imgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.imgUrls.push(ele.response.data)
				}
			})
		},
		//投票选项图片上传
		uploadIndex(index) {
			this.activeIndex = index
		},
		voteImgChange(data) {
			this.form.voteUpdateCandidateDTOList[this.activeIndex].imgUrls = []
			if(data.file.status == 'done') {
				this.form.voteUpdateCandidateDTOList[this.activeIndex].imgUrls.push(data.file.response.data)
			}
		},
		// beforeUpload(f, l) {
		// 	const isLt50M = f.size / 1024 / 1024 < 50;
		// 	if (!isLt50M) {
		// 		this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
		// 		return false;
		// 	} else {
		// 		return true;
		// 	}
		// },
		//修改时间
		changeTime(val) {
			this.form.beginDate = val[0];
            this.form.endDate = val[1];
		},
		//添加投票选项
		addVoteItem() {
			let obj = {
				id: null,
				name: '',
				tel: '',
				imgUrls: [],
			};
			this.form.voteUpdateCandidateDTOList.push(obj)			
		},
		//删除投票选项
		deleteVoteItem(item,index) {
			this.deleteId.push(item.id);
			this.form.voteUpdateCandidateDTOList.splice(index,1)
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改投票";
					this.activeId = val;
					findVote({ voteId: val }).then((res) => {
						let data = res.data;
						//处理选项
						let arr = []
						data.voteFBICandidateVoList.forEach(ele => {
							let obj = {
								id: ele.id,
								name: ele.name,
								tel: ele.tel,
								imgUrls: [],
							}
							if(ele.imgList.length > 0) {
								obj.imgUrls.push(ele.imgList[0].url);
							};
							arr.push(obj);
						})
						////////
						this.form = {
							title: data.title,
							content: data.content,
							beginDate: data.beginDate,
							endDate: data.endDate,
							type: data.type,
							isRelease: data.isRelease,
							voteUpdateCandidateDTOList: arr,
							imgUrls: [],
						};
						this.selTime = [res.data.beginDate, res.data.endDate];
						if (res.data.imgList.length > 0) {
							const pic = [];
							this.form.imgUrls.push(res.data.imgList[0].url);
							for (let item of res.data.imgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								pic.push(obj);
							}
							this.fileList = pic;
						} else {
							this.form.imgUrls = [];
						}
					});
				} else {
					this.title = "新增投票";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="less">
.vote-item {
	width: 600px;
	.vote-item-title {
		display: flex;
	}
}
</style>
