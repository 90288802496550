import httpService from "@/request"

// 查询所有投票信息
export function getVoteList(params) {
	return httpService({
		url: `/user/vote/list`,
		method: 'get',
		params: params,
	})
}

// 添加投票信息
export function addVote(params) {
	return httpService({
		url: `/user/vote/insert`,
		method: 'post',
		data: params,
	})
}

// 删除投票信息
export function deleteVote(params) {
	return httpService({
		url: `/user/vote/delete`,
		method: 'post',
		data: params,
	})
}

// 根据投票主键id查询投票信息
export function findVote(params) {
	return httpService({
		url: `/user/vote/findById`,
		method: 'get',
		params: params,
	})
}

// 修改投票信息
export function updateVote(params) {
	return httpService({
		url: `/user/vote/update`,
		method: 'post',
		data: params,
	})
}

// 发布下架投票
export function releaseVote(params) {
	return httpService({
		url: `/user/vote/isRelease`,
		method: 'get',
		params: params,
	})
}

// 查询候选项信息(详情页面)
export function getCandidateList(params) {
	return httpService({
		url: `/user/vote/findCandidateList`,
		method: 'get',
		params: params,
	})
}

// 查询候选人被投票信息(详情页面)
export function getPersonalList(params) {
	return httpService({
		url: `/user/vote/findPersonnelList`,
		method: 'get',
		params: params,
	})
}

// 即将开始的投票个数
export function getTodayVote(params) {
	return httpService({
		url: `/user/vote/countVoteExpectedStart`,
		method: 'get',
		params: params,
	})
}