<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>投票管理</span>
            </a-space>
        </div>
        <div class="search-box" v-if="todayVote > 0">
            <a-alert :message="'温馨提示：有 '+todayVote+' 个投票即将开始'" type="warning" show-icon />
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.title" style="width: 200px" placeholder="投票标题"></a-input>
                    <a-select v-model="form.status" style="width: 200px" placeholder="投票状态">
                        <a-select-option :value="1">未开始</a-select-option>
                        <a-select-option :value="2">进行中</a-select-option>
                        <a-select-option :value="3">已结束</a-select-option>
                    </a-select>
                    <a-range-picker v-model="selTime" @change="timeChange" value-format="YYYY-MM-DD HH:mm:ss"></a-range-picker>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="addScroll">新增投票</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="release" slot-scope="text,record">
                    <a-switch checked-children="已发布"
                        un-checked-children="未发布"
                        :checked="record.isRelease == 1"
                        @change="handleRelease(record)">
                    </a-switch>
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=detail(record)>投票详情</a></span>
                    <span><a style="margin-left: 8px" @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <!-- <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div> -->
        </div>
        <addForm :show="drawerShow" @success="success" @addClose="addClose" :editId="null"></addForm>
        <editForm :show="editDrawerShow" @success="success" @addClose="editClose" :editId="editId"></editForm>
        <a-drawer title="投票详情" :width="720" :visible="detailShow" :body-style="{ paddingBottom: '80px' }" @close="detailClose">
            <span style="font-weight: 600">投票信息</span>
            <a-divider></a-divider>
            <a-descriptions :column="2">
                <a-descriptions-item label="投票标题">{{detailTotal.title}}</a-descriptions-item>
                <a-descriptions-item label="公开人群">
                    {{detailTotal.type==1?'全部':detailTotal.type==2?'业主':detailTotal.type==3?'业主亲属':detailTotal.type==4?'租户':'租户亲属'}}
                </a-descriptions-item>
                <a-descriptions-item label="开始时间">{{detailTotal.beginDate}}</a-descriptions-item>
                <a-descriptions-item label="结束时间">{{detailTotal.endDate}}</a-descriptions-item>
                <a-descriptions-item label="图片" :span="3">
                    <img :src="$ImgUrl(img.url)" class="contentImg" v-for="(img, index) in detailTotal.imgList" :key="index" />
                </a-descriptions-item>
                <a-descriptions-item label="内容" :span="3">
                    {{detailTotal.content}}
                </a-descriptions-item>
            </a-descriptions>
            <a-divider></a-divider>
            <a-table :columns="detailColumns" :data-source="detailData" :pagination="false" 
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="img" slot-scope="text,record">
                    <img :src="$ImgUrl(img.url)" class="tableImg" v-for="(img, index) in record.imgList" :key="index" />
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=voteDetail(record)>详情</a></span>
                </template>
            </a-table>
            <a-drawer title="投票人列表" :width="720" :visible="voteShow" :body-style="{ paddingBottom: '80px' }" @close="voteShow = false">
                <a-table :columns="voteColumns" :data-source="voteData" :pagination="votePagination"  @change="votePChange"
                    :row-key="
                    (record, index) => {
                    return record.id;
                    }">
                    <template slot="roomList" slot-scope="text,record">
                        <div v-for="(item,index) in record.roomList" :key="index">
                            {{item.buildingName}}栋-{{item.unitName}}单元-{{item.estateName}}-
                            {{item.identity == 1?'业主':item.identity == 2?'业主亲属':item.identity == 3?'租户':'租户亲属'}}
                        </div>
                    </template>
                </a-table>
            </a-drawer>
        </a-drawer>
    </div>
</template>

<script>
import { rules, columns, pagination, detailColumns,voteColumns,votePagination } from "./depend/config.js";
import addForm from "./depend/form.vue";
import editForm from "./depend/editForm.vue";
import { getVoteList, deleteVote, releaseVote,findVote, getCandidateList,getPersonalList,getTodayVote} from "@/api/operation/vote"
export default {
    components: {
		addForm,
        editForm,
	},
    data() {
        return {
            todayVote: 0,
            form: {
                title: '',
                status: undefined,
                beginDate: '',
                endDate: ''
            },
            selTime: [],
            rules: rules,
            columns: columns,
            voteColumns: voteColumns,
            detailColumns: detailColumns,
            tableData: [],
            pagination: pagination,
            votePagination: votePagination,
            //批量删除
            selectedRowKeys: [],
            //抽屉
            drawerShow: false,
            editId: null,
            editDrawerShow: false,
            //详情抽屉
            detailShow: false,
            detailData: [],
            detailIndex: undefined,
            detailTotal: {
                title: '',
                content: '',
                beginDate: '',
                endDate: '',
                type: 0,
                isRelease: 0,
                imgList: [],
            },
            //投票详情抽屉
            voteShow: false,
            voteData: [],
        }
    },
    created() {
        getTodayVote().then(res => {
            this.todayVote = res.data
        })
        this.getData()
    },
    methods: {
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getVoteList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                title: '',
                status: undefined,
                beginDate: '',
                endDate: ''
            },
            this.selTime = [],
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        //编辑
        edit(val) {
            if(val.status == 2) {
                this.$confirm({
                    title: "该活动已经开始投票，不可编辑",
                    icon:'stop',
                })
                return false;
            } else {
			    this.editId = val.id;
                this.editDrawerShow = true;
            }
        },
        //发布
        handleRelease(val) {
            if(val.status == 1 && val.isRelease == 0) {
                this.$confirm({
                    title: "该投票还未开始，是否发布？",
                    icon:'question',
                    onOk:async()=>{
                        let res = await releaseVote({voteId: val.id})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
            } else {
                releaseVote({voteId: val.id}).then(res => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        //删除
        del(val) {
            if(val.status == 2) {
                this.$confirm({
                    title: "该活动已经开始投票，不可删除",
                    icon:'stop',
                });
                return false;
            } else {
                this.$confirm({
                    title: "是否删除该投票？",
                    icon:'delete',
                    onOk:async()=>{
                        let res = await deleteVote({voteIds: [val.id]})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
            }
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的投票？",
				icon:'delete',
				onOk:async()=>{
                        let res = await deleteVote({voteIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        // 新增问卷
        addScroll() {
            this.drawerShow = true;
        },
        addClose() {
			this.drawerShow = false;
			this.editId = null;
		},
        editClose() {
            this.editDrawerShow = false;
			this.editId = null;
        },
        success() {
			this.getData();
		},
        //详情
        detail(val) {
            let obj = {pageNum: 1,size: 20,voteId: val.id}
            this.detailIndex = val.id;
            findVote({voteId: val.id}).then(res => {
                let data = res.data
                this.detailTotal = {
                    title: data.title,
                    type: data.type,
                    content: data.content,
                    beginDate: data.beginDate,
                    endDate: data.endDate,
                    isRelease: data.isRelease,
                    imgList: data.imgList,
                }
            })
            getCandidateList(obj).then(res => {
                this.detailData = res.data.rows;
                this.detailShow = true;
            })
        }, //投票者列表
        voteDetail(val) {
            let obj = {pageNum: this.votePagination.current,size: this.votePagination.pageSize,voteId: this.detailIndex,voteCandidateId: val.id}
            getPersonalList(obj).then(res => {
                let data = res.data;
                this.voteData = data.rows;
                this.voteShow = true;
            })
        }, //投票者列表分页
        votePChange(val) {

        },
        //关闭详情
        detailClose() {
            this.detailIndex = undefined
            this.detailShow = false
        },
        //修改时间
        timeChange(val) {
            this.form.beginDate = val[0];
            this.form.endDate = val[1];
        },
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
.contentImg {
    height: 108px;
    width: 194px;
}
.tableImg {
    height: 54px;
    width: 97px;
}
</style>