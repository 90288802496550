export const columns = [
	{
		title: "投票标题",
		width: "12%",
		dataIndex: "title",
	},
	{
		title: "投票开始时间",
		width: "15%",
		dataIndex: "beginDate",
	},
	{
		title: "投票结束时间",
		width: "15%",
		dataIndex: "endDate",
	},
	{
		title: "投票状态",
		width: "8%",
		dataIndex: "status",
		customRender: function(status) {
			switch(status) {
				case 1: return '未开始';
				case 2: return '进行中';
				case 3: return '已结束';
			}
		}
	},
	{
		title: "投票候选项数量",
		width: "10%",
		dataIndex: "votePersonnelNum",
	},
	{
		title: "投票结果",
		width: "15%",
		dataIndex: "voteResult",
	},
	{
		title: "是否发布",
		width: "8%",
		dataIndex: "isRelease",
		scopedSlots: { customRender: "release" },
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
];

export const detailColumns = [
	{
		title: "选项名称",
		width: "20%",
		dataIndex: "name",
	},
	{
		title: "手机号",
		width: "20%",
		dataIndex: "tel",
	},
	{
		title: "票数",
		width: "10%",
		dataIndex: "total",
	},
	{
		title: "照片",
		width: "20%",
		dataIndex: "imgList",
		scopedSlots: { customRender: "img" },
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "120",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const voteColumns = [
	{
		title: "住户房号",
		width: "30%",
		dataIndex: "roomList",
		scopedSlots: { customRender: "roomList" },
	},
	{
		title: "住户姓名",
		width: "15%",
		dataIndex: "name",
	},
	{
		title: "手机号",
		width: "20%",
		dataIndex: "tel",
	},
	{
		title: "投票时间",
		width: "35%",
		dataIndex: "createDate",
	},
];

export const pagination= {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
};

export const votePagination = {
	current: 1,
	total: 0,
	pageSize: 25,
	showTotal: (total) => `共 ${total} 条`,
	showQuickJumper: true,
}

export const form = {
	title: '',
	content: '',
	beginDate: '',
	endDate: '',
	type: 1,
	isRelease: 0,
	voteInsertCandidateDTOList: [],
	imgUrls: [],
};

export const rules = {
	title: [{ required: true, message: "请输入标题", trigger: "blur" }],
	content: [{ required: true, message: "请输入内容", trigger: "blur" }],
	type: [{ required: true, message: "请选择对象", trigger: "change" }],
	isRelease: [{ required: true, message: "请选择是否发布", trigger: "change" }],
}